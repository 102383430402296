import React from 'react'

import { Helmet } from 'react-helmet'

import './resume.css'

const Resume = (props) => {
  return (
    <div className="resume-container">
      <Helmet>
        <title>Resume - (((TW)))</title>
        <meta
          name="description"
          content="Travis Wyche is an interdisciplinary artist, musician, researcher, designer, &amp; philosopher of the expanded field."
        />
        <meta property="og:title" content="Resume - (((TW)))" />
        <meta
          property="og:description"
          content="Travis Wyche is an interdisciplinary artist, musician, researcher, designer, &amp; philosopher of the expanded field"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/19ff8ecf-fa1b-4301-ab12-51fbfa4fe15d/bd0535a6-dd91-4ae5-8917-ab7e23784324?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="resume-faq7 thq-section-padding">
        <div className="resume-max-width thq-section-max-width">
          <div className="thq-flex-column resume-section-title">
            <span className="resume-text">
              <span className="resume-text001">web3</span>
              <br className="resume-text002"></br>
              <span className="resume-text003">resume</span>
              <br></br>
            </span>
          </div>
          <div className="thq-flex-column resume-list">
            <div className="resume-list-item1">
              <a
                href="https://hackmd.io/@esm/B1gwLvj0T"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text005"
              >
                <span>HPGF</span>
                <span className="resume-text007">x</span>
                <span>ESM 2024-present</span>
              </a>
              <span className="resume-text009">
                <span>💎 UX researcher, DevRel, UserRel, strategy</span>
                <br></br>
                <span>🧠 The HPGF</span>
                <span className="resume-text013">x</span>
                <span>
                  ESM project, or Hyperactive Public Goods Funding Ecosystem
                  Support Machine, aims to serve as a catalyst for change,
                  synthesizing a diverse array of funding mechanisms into a
                  cohesive and sustainable funding machine that propels
                  impactful projects forward
                </span>
                <br></br>
              </span>
            </div>
            <div className="resume-list-item2">
              <a
                href="https://yeet.haus/"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text016"
              >
                Yeeter 2023-Ongoing
              </a>
              <span className="resume-text017">
                <span>💎 UX/UI Lead</span>
                <br></br>
                <span>🧠</span>
                <span> </span>
                <span>
                  Yeeter is an unruggable web3 crowdfunding solution, summoning
                  a Moloch DAO for every campaign
                </span>
                <br></br>
              </span>
            </div>
            <div className="resume-list-item3">
              <a
                href="https://bootleggers.wtf/"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text024"
              >
                Bootleggers 2023-Ongoing
              </a>
              <span className="resume-text025">
                <span>💎 UX/UI Lead</span>
                <br></br>
                <span>🧠</span>
                <span> </span>
                <span>
                  Custom DAO builds and interfaces for a diverse range of
                  clients, including Bufficorn Ventures and Gitcoin FDD
                </span>
                <br></br>
              </span>
              <a
                href="https://mirror.xyz/0xbootleggers.eth/tpb3SHvmPAyFAxxdemd5RzzKfpYyvNHvZKTtl1bcl_o"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text032"
              >
                <span>👁️</span>
                <span> Bootlegging a DAO Solution to the Metacrisis</span>
              </a>
              <a
                href="https://invoke.haus/"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text035"
              >
                <span>
                  👁️ Invoke: a custom easy mode Moloch DAO summoner for the
                  Arbitrum ecosystem
                </span>
                <br></br>
              </a>
            </div>
            <div className="resume-list-item4">
              <a
                href="https://www.gitcoin.co/"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text038"
              >
                Gitcoin x Supermodular x Pluriverse 2021-Ongoing
              </a>
              <span className="resume-text039">
                <span>💎 </span>
                <span>
                  Lead lorecrafter, designer, art director, DAO ops, creative
                  strategy
                </span>
                <br></br>
                <span>
                  🧠 Co-founded the Pluriverse project with Kevin Owocki, a
                  reimagining of the ConsenSys Celarius Universe Guide and
                  experiment in DAO-ified decentralized lore for the Ethereum
                  ecosystem
                </span>
                <br></br>
                <span>Some of our productions:</span>
                <br></br>
              </span>
              <a
                href="https://pluriverse.metalabel.app/ambiguities"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text047"
              >
                <span>👁️ </span>
                <span>
                  Ambiguities for Discontinuities, a Pluriverse Concept Album
                </span>
              </a>
              <a
                href="https://greenpill.network/pdf/Comic3.pdf"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text050"
              >
                <span>👁️ </span>
                <span>Gitcoin Comics #3: The Phantasmagoric Moloch</span>
              </a>
              <a
                href="https://greenpill.network/pdf/Comic4.pdf"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text053"
              >
                <span>👁️ </span>
                <span>Gitcoin Comics #4: The Nightmare of Moloch</span>
              </a>
              <a
                href="https://greenpill.network/pdf/attractors.pdf"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text056"
              >
                <span>👁️ </span>
                <span>Gitcoin Comics #5: Attractors! Attactors!</span>
              </a>
              <a
                href="https://greenpill.network/pdf/Crypto_OG_Book.pdf"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text059"
              >
                <span>👁️ </span>
                <span>Stuff Crypto OGs Know</span>
              </a>
              <a
                href="https://store.supermodular.xyz/products/a-guide-to-the-pluriverse-digital"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text062"
              >
                <span>👁️ </span>
                <span>A Guide to the Pluriverse</span>
              </a>
              <span className="resume-text065">
                <span>gitcoin comics #6 coming november 2024!!!</span>
                <br></br>
              </span>
            </div>
            <div className="resume-list-item5">
              <a
                href="https://www.raidguild.org/"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text068"
              >
                RaidGuild 2020-Ongoing
              </a>
              <span className="resume-text069">
                <span>
                  💎 Freelance mercenary offering the best contracted UX
                  research and UI design in the Ethereum/EVM ecosystem 
                </span>
                <br></br>
                <span>
                  💎 Newly appointed Handbook Maintainer and Brand Steward
                </span>
                <br></br>
                <span>🧠 </span>
                <span>
                  Continuous iterative improvements on the guilds internal
                  processes, including dao ops, sop, and our own crm called:
                </span>
                <br></br>
              </span>
              <a
                href="https://dm.raidguild.org/"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text077"
              >
                <span>👁️ </span>
                <span>Dungeon Master</span>
              </a>
            </div>
            <div className="resume-list-item6">
              <a
                href="https://daohaus.club/"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text080"
              >
                DAOhaus 2020-Ongoing
              </a>
              <span className="resume-text081">
                <span>💎 UI design, UX research</span>
                <br></br>
                <span>
                  🧠 grant writing, internal ops, progressive decentralization
                  strategy, metagovernance design, content writing, host of
                  weekly haus party live podcast
                </span>
              </span>
              <a
                href="https://daohaus.mirror.xyz/um3tR2VkuoeaO1HjtNz6TQFG_hwAM8ySw2SWAegyvAM"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text085"
              >
                <span>
                  👁️ Decoding the DAO: When We Stopped Trying to Fit Square Pegs
                  into Round Holes and Just Wrote the Manual Instead
                </span>
                <br></br>
              </a>
            </div>
            <div className="resume-list-item61">
              <a
                href="https://molochdao.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="resume-text088"
              >
                MolochDAO 2020-2022
              </a>
              <span className="resume-text089">
                <span>
                  💎 Founded the Really Boring Guild to reboot DAO ops
                </span>
                <br></br>
                <span>🧠 </span>
                <span>Successfully migrated the DAO from Moloch v1 to v2</span>
                <br></br>
                <span>🧠 </span>
                <span>
                  Oversaw the creation and formalization of grant procedures
                </span>
                <br></br>
                <span>🧠 </span>
                <span>
                  Initiated numerous partnerships with other DAOs within the
                  Moloch ecosystem
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resume
